.signin-container {
    height: 90vh;
    display: grid;
    place-items: center;
    /* background-color: #adadad; */
    grid-template-columns: 1fr;
    padding: 2rem;
}

.signin-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #000;
    height: 100%;
}